/* neue-haas-grotesk font family */
/*@import url('https://fonts.cdnfonts.com/css/neue-haas-grotesk-display-pro?styles=82961,82967,82965,82963,82959');*/
/* usage */
/* font-family: 'Neue Haas Grotesk Display Pro', sans-serif; */

/* program font family */
@font-face {
  font-family: 'program';
  src: url('./assets/font/program-fonts/fonnts.com-Program_OT_Medium.otf') format('opentype'),
    url('./assets/font/program-fonts/fonnts.com-Program_OT_Book.otf') format('opentype'),
    url('./assets/font/program-fonts/fonnts.com-Program_OT_Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

/* ----------------------------------------- */
/* / Neue Haas Grotesk Display Pro / */

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('./assets/font/neue-haas-grotesk-display-pro/NeueHaasDisplayRoman.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('./assets/font/neue-haas-grotesk-display-pro/NeueHaasDisplayMediu.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('./assets/font/neue-haas-grotesk-display-pro/NeueHaasDisplayBold.ttf');
  font-weight: 700;
}

/* / ------------------------------------------------------- / */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Neue Haas Grotesk Display Pro', sans-serif;
  /* font-family: 'program', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
